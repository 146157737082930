import Head from "next/head";

const PageHead = ({ post, title, category, description }) => {
  if(post){
    title = post.title
    description = post.description.short  
  }
  let newTitle = (category) ? (title + " | " + category) : title
  let desc = (description) ? description : "Learn AI/ML, Cloud Computing, DevSecOps & Cybersecurity"
  newTitle = newTitle + " | CloudPedia.AI"

  return (
    <>
      <Head>
        <title>{newTitle}</title>
        <meta name="description" content={desc} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
    </>
  );
};

export default PageHead;
