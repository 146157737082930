import React, { createContext, useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GoogleAnalytics } from '@next/third-parties/google'

export const CreateContext = createContext();

export const useAppContext = () => useContext(CreateContext);

const Context = ({ children }) => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.CartReducer);

  const [cartToggle, setCart] = useState(true);
  const [toggle, setToggle] = useState(true);
  const [search, setSearch] = useState(true);
  const [mobile, setMobile] = useState(true);
  const [smallMobileMenu, setsmallMobileMenu] = useState(true);

  useEffect(() => {
    dispatch({ type: "COUNT_CART_TOTALS" });
  }, [cart]);

  return (
    <>
      <CreateContext.Provider
        value={{
          toggle,
          setToggle,
          mobile,
          setMobile,
          smallMobileMenu,
          setsmallMobileMenu,
          cartToggle,
          setCart,
          search,
          setSearch,
        }}
      >
        {children}
      </CreateContext.Provider>
      <GoogleAnalytics gaId="G-VRGJWKCQEW" />
    </>
  );
};

export default Context;
