import Link from "next/link";
import Separator from "../Common/Separator";

const CopyRight = () => {
  return (
    <>
      <Separator />
      <div className="copyright-area copyright-style-1 ptb--20">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <p className="rbt-link-hover text-center text-lg-start">
                Copyright © 2024{" "}
                <Link href="https://akbarhussain.com/">
                  Akbar Hussain.
                </Link>{" "}
                All Rights Reserved.  Image resource: Depositphotos, OpenAI Dall-E-3, Microsoft Copilot
              </p>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <ul className="copyright-link rbt-link-hover justify-content-center justify-content-lg-end mt_sm--10 mt_md--10">
                <li>
                  <Link href="/legal/terms-of-use">Terms of Use</Link>
                </li>
                <li>
                  <Link href="/legal/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link href="#">Subscription</Link>
                </li>
                <li>
                  <Link href="#">Login & Register</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyRight;
